html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  color:#000;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"),
    url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap) format("woff2");
}

@font-face {
	font-family: 'Ariston';
	src:url('../assets/fonts/AristonComicFont.otf');
	src:url('../assets/fonts/AristonComicFont.otf?#iefix') format('embedded-opentype'),
		url('../assets/fonts/AristonComicFont.otf') format('otf'),
		url('../assets/fonts/AristonComicFont.ttf') format('truetype'),
}


body {
  margin: 0;
  font-family: "Ubuntu", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body a {
  color: #fff;
  text-decoration: none;
  font-family: "Ubuntu";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#ffffff";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

input{
  padding: 0.5em;
  margin: 0.5em;
  color: #000;
  background: white;
  max-width:100px;
  width:100px;
  text-align:center;
  border: none;
  border-radius: 3px;
  font-size:20px;
  font-weight:bold;
}

label{
  text-align: center;;
}



#main-header {
  background: url(../assets/images/backgrounds/bg-menu.png) repeat-x center top;
  z-index: 1001;
  height: 6.33333rem;
  margin-top: 1.66667rem;
  position: relative;
  width:100%;
}

/* line 55, ../scss/_app-styles.scss */
#main-header .logo {
  margin-top: 1.4rem;
}
@media screen and (min-width: 64em) {
  /* line 55, ../scss/_app-styles.scss */
  #main-header .logo {
    margin-top: 1.2rem;
  }
}
/* line 63, ../scss/_app-styles.scss */
#main-header #main-nav ul.main-menu {
  text-align: right;
}
@media screen and (min-width: 64em) {
  /* line 63, ../scss/_app-styles.scss */
  #main-header #main-nav ul.main-menu {
    margin: 2.06667rem 0 0;
  }
}
/* line 68, ../scss/_app-styles.scss */
#main-header #main-nav ul.main-menu li {
  display: inline-block;
  margin-right: 2.66667rem;
}
/* line 71, ../scss/_app-styles.scss */
#main-header #main-nav ul.main-menu li:last-child {
  margin-right: 0;
}
/* line 76, ../scss/_app-styles.scss */
#main-header #main-nav ul.mobile-menu {
  text-align: center;
  margin: 0;
}
/* line 81, ../scss/_app-styles.scss */
#main-header #main-nav ul li a {
  font-size: 1.2rem;
  text-transform: uppercase;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
/* line 86, ../scss/_app-styles.scss */
#main-header #main-nav ul li a:hover, #main-header #main-nav ul li a:active, #main-header #main-nav ul li a:focus, #main-header #main-nav ul li a.active {
  color: #c5db3b;
}

/* line 1, ../scss/_mobile-menu.scss */
.mobile-menu {
  display: none;
}

@media screen and (max-width: 63.9375em) {
  /* line 6, ../scss/_mobile-menu.scss */
  .main-menu {
    display: none;
  }

  /* line 9, ../scss/_mobile-menu.scss */
  .mobile-menu {
    display: block;
    width: 100%;
    float: left;
    margin: 0 !important;
  }
}
/* line 17, ../scss/_mobile-menu.scss */
.slicknav_btn {
  position: absolute;
  right: 14px;
  top: 26px;
  padding: 0;
}

/* line 24, ../scss/_mobile-menu.scss */
.slicknav_nav {
  width: 100%;
  list-style-type: none;
  text-align: center;
  float: right;
  text-transform: uppercase;
  position: absolute;
  top: 75px;
  left: 0;
  background: #302429;
  margin-left: 0;
  padding: 2rem 0;
}
/* line 37, ../scss/_mobile-menu.scss */
.slicknav_nav li {
  display: block;
  padding: 0.66667rem 0;
}

/* line 43, ../scss/_mobile-menu.scss */
.slicknav_menu {
  background: transparent;
  padding: 0;
}

/* line 48, ../scss/_mobile-menu.scss */
.slicknav_menu .slicknav_icon {
  display: none;
}

/* line 52, ../scss/_mobile-menu.scss */
.slicknav_btn {
  background-color: transparent;
  border-radius: 0;
  margin: 0;
  text-decoration: none;
  text-shadow: 0 none;
}



.row{
  max-width:78.66667rem;
  margin-left:auto;
  margin-right:auto
}
.row:before,.row:after{
  content:' ';
  display:table;
  flex-basis:0;
  order:1
}
.row:after{
  clear:both
}
.row.collapse>.column,.row.collapse>.columns{
  padding-left:0;
  padding-right:0
}
.row .row{
  margin-left:-.66667rem;
  margin-right:-.66667rem
}
@media screen and (min-width:40em){
  .row .row{
      margin-left:-1rem;
      margin-right:-1rem
  }
}
.row .row.collapse{
  margin-left:0;
  margin-right:0
}
.row.expanded{
  max-width:none
}
.row.expanded .row{
  margin-left:auto;
  margin-right:auto
}
.column,.columns{
  width:100%;
  float:left;
  padding-left:.66667rem;
  padding-right:.66667rem
}
@media screen and (min-width:40em){
  .column,.columns{
      padding-left:1rem;
      padding-right:1rem
  }
}
.column:last-child:not(:first-child),.columns:last-child:not(:first-child){
  float:right
}
.column.end:last-child:last-child,.end.columns:last-child:last-child{
  float:left
}
.column.row.row,.row.row.columns{
  float:none
}
.row .column.row.row,.row .row.row.columns{
  padding-left:0;
  padding-right:0;
  margin-left:0;
  margin-right:0
}
.small-1{
  width:6.25%
}
.small-push-1{
  position:relative;
  left:6.25%
}
.small-pull-1{
  position:relative;
  left:-6.25%
}
.small-offset-0{
  margin-left:0
}
.small-2{
  width:12.5%
}
.small-push-2{
  position:relative;
  left:12.5%
}
.small-pull-2{
  position:relative;
  left:-12.5%
}
.small-offset-1{
  margin-left:6.25%
}
.small-3{
  width:18.75%
}
.small-push-3{
  position:relative;
  left:18.75%
}
.small-pull-3{
  position:relative;
  left:-18.75%
}
.small-offset-2{
  margin-left:12.5%
}
.small-4{
  width:25%
}
.small-push-4{
  position:relative;
  left:25%
}
.small-pull-4{
  position:relative;
  left:-25%
}
.small-offset-3{
  margin-left:18.75%
}
.small-5{
  width:31.25%
}
.small-push-5{
  position:relative;
  left:31.25%
}
.small-pull-5{
  position:relative;
  left:-31.25%
}
.small-offset-4{
  margin-left:25%
}
.small-6{
  width:37.5%
}
.small-push-6{
  position:relative;
  left:37.5%
}
.small-pull-6{
  position:relative;
  left:-37.5%
}
.small-offset-5{
  margin-left:31.25%
}
.small-7{
  width:43.75%
}
.small-push-7{
  position:relative;
  left:43.75%
}
.small-pull-7{
  position:relative;
  left:-43.75%
}
.small-offset-6{
  margin-left:37.5%
}
.small-8{
  width:50%
}
.small-push-8{
  position:relative;
  left:50%
}
.small-pull-8{
  position:relative;
  left:-50%
}
.small-offset-7{
  margin-left:43.75%
}
.small-9{
  width:56.25%
}
.small-push-9{
  position:relative;
  left:56.25%
}
.small-pull-9{
  position:relative;
  left:-56.25%
}
.small-offset-8{
  margin-left:50%
}
.small-10{
  width:62.5%
}
.small-push-10{
  position:relative;
  left:62.5%
}
.small-pull-10{
  position:relative;
  left:-62.5%
}
.small-offset-9{
  margin-left:56.25%
}
.small-11{
  width:68.75%
}
.small-push-11{
  position:relative;
  left:68.75%
}
.small-pull-11{
  position:relative;
  left:-68.75%
}
.small-offset-10{
  margin-left:62.5%
}
.small-12{
  width:75%
}
.small-push-12{
  position:relative;
  left:75%
}
.small-pull-12{
  position:relative;
  left:-75%
}
.small-offset-11{
  margin-left:68.75%
}
.small-13{
  width:81.25%
}
.small-push-13{
  position:relative;
  left:81.25%
}
.small-pull-13{
  position:relative;
  left:-81.25%
}
.small-offset-12{
  margin-left:75%
}
.small-14{
  width:87.5%
}
.small-push-14{
  position:relative;
  left:87.5%
}
.small-pull-14{
  position:relative;
  left:-87.5%
}
.small-offset-13{
  margin-left:81.25%
}
.small-15{
  width:93.75%
}
.small-push-15{
  position:relative;
  left:93.75%
}
.small-pull-15{
  position:relative;
  left:-93.75%
}
.small-offset-14{
  margin-left:87.5%
}
.small-16{
  width:100%;
}
.small-offset-15{
  margin-left:93.75%
}
.small-up-1>.column,.small-up-1>.columns{
  width:100%;
  float:left
}
.small-up-1>.column:nth-of-type(1n),.small-up-1>.columns:nth-of-type(1n){
  clear:none
}
.small-up-1>.column:nth-of-type(1n+1),.small-up-1>.columns:nth-of-type(1n+1){
  clear:both
}
.small-up-1>.column:last-child,.small-up-1>.columns:last-child{
  float:left
}
.small-up-2>.column,.small-up-2>.columns{
  width:50%;
  float:left
}
.small-up-2>.column:nth-of-type(1n),.small-up-2>.columns:nth-of-type(1n){
  clear:none
}
.small-up-2>.column:nth-of-type(2n+1),.small-up-2>.columns:nth-of-type(2n+1){
  clear:both
}
.small-up-2>.column:last-child,.small-up-2>.columns:last-child{
  float:left
}
.small-up-3>.column,.small-up-3>.columns{
  width:33.33333%;
  float:left
}
.small-up-3>.column:nth-of-type(1n),.small-up-3>.columns:nth-of-type(1n){
  clear:none
}
.small-up-3>.column:nth-of-type(3n+1),.small-up-3>.columns:nth-of-type(3n+1){
  clear:both
}
.small-up-3>.column:last-child,.small-up-3>.columns:last-child{
  float:left
}
.small-up-4>.column,.small-up-4>.columns{
  width:25%;
  float:left
}
.small-up-4>.column:nth-of-type(1n),.small-up-4>.columns:nth-of-type(1n){
  clear:none
}
.small-up-4>.column:nth-of-type(4n+1),.small-up-4>.columns:nth-of-type(4n+1){
  clear:both
}
.small-up-4>.column:last-child,.small-up-4>.columns:last-child{
  float:left
}
.small-up-5>.column,.small-up-5>.columns{
  width:20%;
  float:left
}
.small-up-5>.column:nth-of-type(1n),.small-up-5>.columns:nth-of-type(1n){
  clear:none
}
.small-up-5>.column:nth-of-type(5n+1),.small-up-5>.columns:nth-of-type(5n+1){
  clear:both
}
.small-up-5>.column:last-child,.small-up-5>.columns:last-child{
  float:left
}
.small-up-6>.column,.small-up-6>.columns{
  width:16.66667%;
  float:left
}
.small-up-6>.column:nth-of-type(1n),.small-up-6>.columns:nth-of-type(1n){
  clear:none
}
.small-up-6>.column:nth-of-type(6n+1),.small-up-6>.columns:nth-of-type(6n+1){
  clear:both
}
.small-up-6>.column:last-child,.small-up-6>.columns:last-child{
  float:left
}
.small-collapse>.column,.small-collapse>.columns{
  padding-left:0;
  padding-right:0
}
.small-collapse .row{
  margin-left:0;
  margin-right:0
}
.small-uncollapse>.column,.small-uncollapse>.columns{
  padding-left:.66667rem;
  padding-right:.66667rem
}
.small-centered{
  float:none;
  margin-left:auto;
  margin-right:auto
}
.small-uncentered,.small-push-0,.small-pull-0{
  position:static;
  margin-left:0;
  margin-right:0;
  float:left
}
@media screen and (min-width:40em){
  .medium-1{
      width:6.25%
  }
  .medium-push-1{
      position:relative;
      left:6.25%
  }
  .medium-pull-1{
      position:relative;
      left:-6.25%
  }
  .medium-offset-0{
      margin-left:0
  }
  .medium-2{
      width:12.5%
  }
  .medium-push-2{
      position:relative;
      left:12.5%
  }
  .medium-pull-2{
      position:relative;
      left:-12.5%
  }
  .medium-offset-1{
      margin-left:6.25%
  }
  .medium-3{
      width:18.75%
  }
  .medium-push-3{
      position:relative;
      left:18.75%
  }
  .medium-pull-3{
      position:relative;
      left:-18.75%
  }
  .medium-offset-2{
      margin-left:12.5%
  }
  .medium-4{
      width:25%
  }
  .medium-push-4{
      position:relative;
      left:25%
  }
  .medium-pull-4{
      position:relative;
      left:-25%
  }
  .medium-offset-3{
      margin-left:18.75%
  }
  .medium-5{
      width:31.25%
  }
  .medium-push-5{
      position:relative;
      left:31.25%
  }
  .medium-pull-5{
      position:relative;
      left:-31.25%
  }
  .medium-offset-4{
      margin-left:25%
  }
  .medium-6{
      width:37.5%
  }
  .medium-push-6{
      position:relative;
      left:37.5%
  }
  .medium-pull-6{
      position:relative;
      left:-37.5%
  }
  .medium-offset-5{
      margin-left:31.25%
  }
  .medium-7{
      width:43.75%
  }
  .medium-push-7{
      position:relative;
      left:43.75%
  }
  .medium-pull-7{
      position:relative;
      left:-43.75%
  }
  .medium-offset-6{
      margin-left:37.5%
  }
  .medium-8{
      width:50%
  }
  .medium-push-8{
      position:relative;
      left:50%
  }
  .medium-pull-8{
      position:relative;
      left:-50%
  }
  .medium-offset-7{
      margin-left:43.75%
  }
  .medium-9{
      width:56.25%
  }
  .medium-push-9{
      position:relative;
      left:56.25%
  }
  .medium-pull-9{
      position:relative;
      left:-56.25%
  }
  .medium-offset-8{
      margin-left:50%
  }
  .medium-10{
      width:62.5%
  }
  .medium-push-10{
      position:relative;
      left:62.5%
  }
  .medium-pull-10{
      position:relative;
      left:-62.5%
  }
  .medium-offset-9{
      margin-left:56.25%
  }
  .medium-11{
      width:68.75%
  }
  .medium-push-11{
      position:relative;
      left:68.75%
  }
  .medium-pull-11{
      position:relative;
      left:-68.75%
  }
  .medium-offset-10{
      margin-left:62.5%
  }
  .medium-12{
      width:75%
  }
  .medium-push-12{
      position:relative;
      left:75%
  }
  .medium-pull-12{
      position:relative;
      left:-75%
  }
  .medium-offset-11{
      margin-left:68.75%
  }
  .medium-13{
      width:81.25%
  }
  .medium-push-13{
      position:relative;
      left:81.25%
  }
  .medium-pull-13{
      position:relative;
      left:-81.25%
  }
  .medium-offset-12{
      margin-left:75%
  }
  .medium-14{
      width:87.5%
  }
  .medium-push-14{
      position:relative;
      left:87.5%
  }
  .medium-pull-14{
      position:relative;
      left:-87.5%
  }
  .medium-offset-13{
      margin-left:81.25%
  }
  .medium-15{
      width:93.75%
  }
  .medium-push-15{
      position:relative;
      left:93.75%
  }
  .medium-pull-15{
      position:relative;
      left:-93.75%
  }
  .medium-offset-14{
      margin-left:87.5%
  }
  .medium-16{
      width:100%
  }
  .medium-offset-15{
      margin-left:93.75%
  }
  .medium-up-1>.column,.medium-up-1>.columns{
      width:100%;
      float:left
  }
  .medium-up-1>.column:nth-of-type(1n),.medium-up-1>.columns:nth-of-type(1n){
      clear:none
  }
  .medium-up-1>.column:nth-of-type(1n+1),.medium-up-1>.columns:nth-of-type(1n+1){
      clear:both
  }
  .medium-up-1>.column:last-child,.medium-up-1>.columns:last-child{
      float:left
  }
  .medium-up-2>.column,.medium-up-2>.columns{
      width:50%;
      float:left
  }
  .medium-up-2>.column:nth-of-type(1n),.medium-up-2>.columns:nth-of-type(1n){
      clear:none
  }
  .medium-up-2>.column:nth-of-type(2n+1),.medium-up-2>.columns:nth-of-type(2n+1){
      clear:both
  }
  .medium-up-2>.column:last-child,.medium-up-2>.columns:last-child{
      float:left
  }
  .medium-up-3>.column,.medium-up-3>.columns{
      width:33.33333%;
      float:left
  }
  .medium-up-3>.column:nth-of-type(1n),.medium-up-3>.columns:nth-of-type(1n){
      clear:none
  }
  .medium-up-3>.column:nth-of-type(3n+1),.medium-up-3>.columns:nth-of-type(3n+1){
      clear:both
  }
  .medium-up-3>.column:last-child,.medium-up-3>.columns:last-child{
      float:left
  }
  .medium-up-4>.column,.medium-up-4>.columns{
      width:25%;
      float:left
  }
  .medium-up-4>.column:nth-of-type(1n),.medium-up-4>.columns:nth-of-type(1n){
      clear:none
  }
  .medium-up-4>.column:nth-of-type(4n+1),.medium-up-4>.columns:nth-of-type(4n+1){
      clear:both
  }
  .medium-up-4>.column:last-child,.medium-up-4>.columns:last-child{
      float:left
  }
  .medium-up-5>.column,.medium-up-5>.columns{
      width:20%;
      float:left
  }
  .medium-up-5>.column:nth-of-type(1n),.medium-up-5>.columns:nth-of-type(1n){
      clear:none
  }
  .medium-up-5>.column:nth-of-type(5n+1),.medium-up-5>.columns:nth-of-type(5n+1){
      clear:both
  }
  .medium-up-5>.column:last-child,.medium-up-5>.columns:last-child{
      float:left
  }
  .medium-up-6>.column,.medium-up-6>.columns{
      width:16.66667%;
      float:left
  }
  .medium-up-6>.column:nth-of-type(1n),.medium-up-6>.columns:nth-of-type(1n){
      clear:none
  }
  .medium-up-6>.column:nth-of-type(6n+1),.medium-up-6>.columns:nth-of-type(6n+1){
      clear:both
  }
  .medium-up-6>.column:last-child,.medium-up-6>.columns:last-child{
      float:left
  }
  .medium-collapse>.column,.medium-collapse>.columns{
      padding-left:0;
      padding-right:0
  }
  .medium-collapse .row{
      margin-left:0;
      margin-right:0
  }
  .medium-uncollapse>.column,.medium-uncollapse>.columns{
      padding-left:1rem;
      padding-right:1rem
  }
  .medium-centered{
      float:none;
      margin-left:auto;
      margin-right:auto
  }
  .medium-uncentered,.medium-push-0,.medium-pull-0{
      position:static;
      margin-left:0;
      margin-right:0;
      float:left
  }
}
@media screen and (min-width:64em){
  .large-1{
      width:6.25%
  }
  .large-push-1{
      position:relative;
      left:6.25%
  }
  .large-pull-1{
      position:relative;
      left:-6.25%
  }
  .large-offset-0{
      margin-left:0
  }
  .large-2{
      width:12.5%
  }
  .large-push-2{
      position:relative;
      left:12.5%
  }
  .large-pull-2{
      position:relative;
      left:-12.5%
  }
  .large-offset-1{
      margin-left:6.25%
  }
  .large-3{
      width:18.75%
  }
  .large-push-3{
      position:relative;
      left:18.75%
  }
  .large-pull-3{
      position:relative;
      left:-18.75%
  }
  .large-offset-2{
      margin-left:12.5%
  }
  .large-4{
      width:25%
  }
  .large-push-4{
      position:relative;
      left:25%
  }
  .large-pull-4{
      position:relative;
      left:-25%
  }
  .large-offset-3{
      margin-left:18.75%
  }
  .large-5{
      width:31.25%
  }
  .large-push-5{
      position:relative;
      left:31.25%
  }
  .large-pull-5{
      position:relative;
      left:-31.25%
  }
  .large-offset-4{
      margin-left:25%
  }
  .large-6{
      width:37.5%
  }
  .large-push-6{
      position:relative;
      left:37.5%
  }
  .large-pull-6{
      position:relative;
      left:-37.5%
  }
  .large-offset-5{
      margin-left:31.25%
  }
  .large-7{
      width:43.75%
  }
  .large-push-7{
      position:relative;
      left:43.75%
  }
  .large-pull-7{
      position:relative;
      left:-43.75%
  }
  .large-offset-6{
      margin-left:37.5%
  }
  .large-8{
      width:50%
  }
  .large-push-8{
      position:relative;
      left:50%
  }
  .large-pull-8{
      position:relative;
      left:-50%
  }
  .large-offset-7{
      margin-left:43.75%
  }
  .large-9{
      width:56.25%
  }
  .large-push-9{
      position:relative;
      left:56.25%
  }
  .large-pull-9{
      position:relative;
      left:-56.25%
  }
  .large-offset-8{
      margin-left:50%
  }
  .large-10{
      width:62.5%
  }
  .large-push-10{
      position:relative;
      left:62.5%
  }
  .large-pull-10{
      position:relative;
      left:-62.5%
  }
  .large-offset-9{
      margin-left:56.25%
  }
  .large-11{
      width:68.75%
  }
  .large-push-11{
      position:relative;
      left:68.75%
  }
  .large-pull-11{
      position:relative;
      left:-68.75%
  }
  .large-offset-10{
      margin-left:62.5%
  }
  .large-12{
      width:75%
  }
  .large-push-12{
      position:relative;
      left:75%
  }
  .large-pull-12{
      position:relative;
      left:-75%
  }
  .large-offset-11{
      margin-left:68.75%
  }
  .large-13{
      width:81.25%
  }
  .large-push-13{
      position:relative;
      left:81.25%
  }
  .large-pull-13{
      position:relative;
      left:-81.25%
  }
  .large-offset-12{
      margin-left:75%
  }
  .large-14{
      width:87.5%
  }
  .large-push-14{
      position:relative;
      left:87.5%
  }
  .large-pull-14{
      position:relative;
      left:-87.5%
  }
  .large-offset-13{
      margin-left:81.25%
  }
  .large-15{
      width:93.75%
  }
  .large-push-15{
      position:relative;
      left:93.75%
  }
  .large-pull-15{
      position:relative;
      left:-93.75%
  }
  .large-offset-14{
      margin-left:87.5%
  }
  .large-16{
      width:100%
  }
  .large-offset-15{
      margin-left:93.75%
  }
  .large-up-1>.column,.large-up-1>.columns{
      width:100%;
      float:left
  }
  .large-up-1>.column:nth-of-type(1n),.large-up-1>.columns:nth-of-type(1n){
      clear:none
  }
  .large-up-1>.column:nth-of-type(1n+1),.large-up-1>.columns:nth-of-type(1n+1){
      clear:both
  }
  .large-up-1>.column:last-child,.large-up-1>.columns:last-child{
      float:left
  }
  .large-up-2>.column,.large-up-2>.columns{
      width:50%;
      float:left
  }
  .large-up-2>.column:nth-of-type(1n),.large-up-2>.columns:nth-of-type(1n){
      clear:none
  }
  .large-up-2>.column:nth-of-type(2n+1),.large-up-2>.columns:nth-of-type(2n+1){
      clear:both
  }
  .large-up-2>.column:last-child,.large-up-2>.columns:last-child{
      float:left
  }
  .large-up-3>.column,.large-up-3>.columns{
      width:33.33333%;
      float:left
  }
  .large-up-3>.column:nth-of-type(1n),.large-up-3>.columns:nth-of-type(1n){
      clear:none
  }
  .large-up-3>.column:nth-of-type(3n+1),.large-up-3>.columns:nth-of-type(3n+1){
      clear:both
  }
  .large-up-3>.column:last-child,.large-up-3>.columns:last-child{
      float:left
  }
  .large-up-4>.column,.large-up-4>.columns{
      width:25%;
      float:left
  }
  .large-up-4>.column:nth-of-type(1n),.large-up-4>.columns:nth-of-type(1n){
      clear:none
  }
  .large-up-4>.column:nth-of-type(4n+1),.large-up-4>.columns:nth-of-type(4n+1){
      clear:both
  }
  .large-up-4>.column:last-child,.large-up-4>.columns:last-child{
      float:left
  }
  .large-up-5>.column,.large-up-5>.columns{
      width:20%;
      float:left
  }
  .large-up-5>.column:nth-of-type(1n),.large-up-5>.columns:nth-of-type(1n){
      clear:none
  }
  .large-up-5>.column:nth-of-type(5n+1),.large-up-5>.columns:nth-of-type(5n+1){
      clear:both
  }
  .large-up-5>.column:last-child,.large-up-5>.columns:last-child{
      float:left
  }
  .large-up-6>.column,.large-up-6>.columns{
      width:16.66667%;
      float:left
  }
  .large-up-6>.column:nth-of-type(1n),.large-up-6>.columns:nth-of-type(1n){
      clear:none
  }
  .large-up-6>.column:nth-of-type(6n+1),.large-up-6>.columns:nth-of-type(6n+1){
      clear:both
  }
  .large-up-6>.column:last-child,.large-up-6>.columns:last-child{
      float:left
  }
  .large-collapse>.column,.large-collapse>.columns{
      padding-left:0;
      padding-right:0
  }
  .large-collapse .row{
      margin-left:0;
      margin-right:0
  }
  .large-uncollapse>.column,.large-uncollapse>.columns{
      padding-left:1rem;
      padding-right:1rem
  }
  .large-centered{
      float:none;
      margin-left:auto;
      margin-right:auto
  }
  .large-uncentered,.large-push-0,.large-pull-0{
      position:static;
      margin-left:0;
      margin-right:0;
      float:left
  }
}

#main-header #main-nav ul.main-menu{
  text-align:right
}
@media screen and (min-width:64em){
  #main-header #main-nav ul.main-menu{
      margin:2.06667rem 0 0
  }
}
#main-header #main-nav ul.main-menu li{
  display:inline-block;
  margin-right:2.66667rem
}
#main-header #main-nav ul.main-menu li:last-child{
  margin-right:0
}
#main-header #main-nav ul.mobile-menu{
  text-align:center;
  margin:0
}

.about-us-summary h2{
  font-family: 'Ariston';
  font-size:4rem;
  color: yellow;
}
#main-header #main-nav ul li a{
  font-family: 'Ariston';
  font-size:1.75rem;
  text-transform:uppercase;
  -webkit-transition:all .1s ease-in;
  -moz-transition:all .1s ease-in;
  -ms-transition:all .1s ease-in;
  -o-transition:all .1s ease-in;
  transition:all .1s ease-in
}
#main-header #main-nav ul li a:hover,#main-header #main-nav ul li a:active,#main-header #main-nav ul li a:focus,#main-header #main-nav ul li a.active{
  color:yellow;
}
.mobile-menu{
  display:none
}
@media screen and (max-width:63.9375em){
  .main-menu{
      display:none
  }
  .mobile-menu{
      display:block;
      width:100%;
      float:left;
      margin:0!important
  }
}
.slicknav_btn{
  position:absolute;
  right:14px;
  top:26px;
  padding:0
}
.slicknav_nav{
  width:100%;
  list-style-type:none;
  text-align:center;
  float:right;
  text-transform:uppercase;
  position:absolute;
  top:75px;
  left:0;
  background:#302429;
  margin-left:0;
  padding:2rem 0
}
.slicknav_nav li{
  display:block;
  padding:.66667rem 0
}
.slicknav_menu{
  background:transparent;
  padding:0
}
.slicknav_menu .slicknav_icon{
  display:none
}
.slicknav_btn{
  background-color:transparent;
  border-radius:0;
  margin:0;
  text-decoration:none;
  text-shadow:0 none
}
.slick-slider{
  position:relative;
  display:block;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-touch-callout:none;
  -khtml-user-select:none;
  -ms-touch-action:pan-y;
  touch-action:pan-y;
  -webkit-tap-highlight-color:transparent
}
.slick-list{
  position:relative;
  display:block;
  overflow:hidden;
  margin:0;
  padding:0
}
.slick-list:focus{
  outline:none
}
.slick-list.dragging{
  cursor:pointer;
  cursor:hand
}
.slick-slider .slick-track,.slick-slider .slick-list{
  -webkit-transform:translate3d(0,0,0);
  -moz-transform:translate3d(0,0,0);
  -ms-transform:translate3d(0,0,0);
  -o-transform:translate3d(0,0,0);
  transform:translate3d(0,0,0)
}
.slick-track{
  position:relative;
  top:0;
  left:0;
  display:block
}
.slick-track:before,.slick-track:after{
  display:table;
  content:''
}
.slick-track:after{
  clear:both
}
.slick-loading .slick-track{
  visibility:hidden
}
.slick-slide{
  display:none;
  float:left;
  height:100%;
  min-height:1px
}
[dir=rtl] .slick-slide{
  float:right
}
.slick-slide img{
  display:block
}
.slick-slide.slick-loading img{
  display:none
}
.slick-slide.dragging img{
  pointer-events:none
}
.slick-initialized .slick-slide{
  display:block
}
.slick-loading .slick-slide{
  visibility:hidden
}
.slick-vertical .slick-slide{
  display:block;
  height:auto;
  border:1px solid transparent
}
.slick-arrow.slick-hidden{
  display:none
}

#home-slider{
  position:relative;
  top:-130px;
  width:100%;
  z-index:500;
  min-height:745px;
  padding-bottom: 50px;
  background-position:center center!important;
  background-repeat:no-repeat!important;
  background-image: url(../assets/images/placeholders/topBk.jpg);
  background-size: cover;
  

}
#home-slider .slick-slide{
  min-height:745px;
  background-position:center center!important;
  background-repeat:no-repeat!important
}
#home-slider .slick-list{
  width:100%!important
}
#home-slider .cta-text{
  position:absolute;
  top:350px;
  width:100%
}
#home-slider .cta-text p{
  font-size:2.33333rem;
  color:#fff;
  margin-bottom:.33333rem;
  line-height:3.33333rem
}
#home-slider .cta-text p span{
  background:url(../assets/images/backgrounds/lettering-bg.png);
  padding:0 .66667rem;
  text-transform:uppercase;
  line-height:2.33333rem
}
#home-slider .cta-button{
  text-align:left;
  font-size:2.33333rem;
  padding:.66667rem 1.66667rem;
  margin-top:1rem
}
#home-slider .cta-button span{
  display:block;
  font-size:.73333rem;
  color:#57711c;
  text-transform:none;
  text-shadow:none;
  margin-top:.33333rem
}
.inner-slider{
  position:relative;
  top:-120px;
  width:100%;
  z-index:500
}
.inner-slider .slick-slide{
  min-height:400px;
  background-position:center center!important;
  background-repeat:no-repeat!important
}
.inner-slider .slick-list{
  width:100%!important
}

  

#home-slider .slick-slide,.inner-slider .slick-slide{
  -webkit-background-size:cover!important;
  -moz-background-size:cover!important;
  -o-background-size:cover!important;
  background-size:cover!important
}

.content-top {
  background: url(../assets/images/backgrounds/bg-body-top.png) repeat-x center top;
  height: 52px;
  margin-top: -150px;
  position: relative;
  z-index: 1000;
  width:100%;
}

.content-to-bottom {
  background: url(../assets/images/backgrounds/bg-body-top.png) repeat-x center top;
  height: 52px;
  margin-top: 0px;
  position: relative;
  z-index: 1000;
  width:100%;
}

.content-bottom-img {
  background: url(../assets/images/backgrounds/bg-body-top.png) repeat-x center top;
  height: 52px;
  position: relative;
  z-index: 1000;
  width:100%;
  transform: rotate(180deg);
}

#bigText{
  font-size:1.5rem;
  line-height:1.75rem;
}

#main-content {
  background: #302429 url(../assets/images/backgrounds/bg-body.jpg) no-repeat center top;
  width:100%;
  padding:50px 0;
}

.about-us-summary p {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.55);
  color:#fff;
  line-height: 1.5rem;
}
@media screen and (min-width: 64em) {
  /* line 201, ../scss/_app-styles.scss */
  .about-us-summary p {
    -webkit-column-gap: 35px;
    -moz-column-gap: 35px;
    column-gap: 35px;
  }
}


.rarities{

-webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -webkit-column-gap: 35px;
    -moz-column-gap: 35px;
    column-gap: 35px;
}
.rareBoxes{
  margin-left:12%;
}
.rareBox{
  color:#fff;
  font-size:18px!important;
  line-height: 1.5rem!important;
  margin-bottom: 20px;
  height:100px;
}
.rareNum{
  font-family: 'Ariston';
  font-size:50px; 
  padding:15px 0 20px;
  text-align: center; 
  margin-right: 20px;
  display: block;
  width: 110px;
  height:100px;
  background: #fff;
  color: #000;
  float: left;
  line-height: 66px;
}

.heroImg{
  width: 60%!important;
  margin-top: 11%;

}

.mintTxtTop{
  color:#000!important; 
  font-size:30px!important; 
  font-weight:bold!important;
}

.mintTxtBtm{
  color:#000!important;
  margin-top:-20px!important; 
  font-family: 'Ariston'!important;
  font-size:60px!important;
  text-shadow: 0 0 5 #ccc!important;
}
.mintLeftSide{
  padding-top:15%;
}

.mintRightSide{
  padding-top:6%;
}
@media (max-width: 1027px) {

  .heroImg{
    width: 49%!important;
    margin-top: 12%;
    
  }

  #home-slider{
    min-height: 515px;
  }
}


@media (max-width: 768px) {
  .rareBox{
    line-height: 1.5rem!important;
    font-size: 1rem!important;

  }
  .heroImg,
  .heroImgMint{
    width: 65%!important;
    margin-top: 14%;
    
  }

  #home-slider{
    min-height: 515px;
  }

  .rareBoxes{
    margin-left:0%;
    
  }
}


.newCallOut{
  font-size: 45px!important;
  color: white;
  font-weight: bold;
  text-align:center;
  line-height: 42px!important;
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  .mintBagsTitle{
    font-size: 50px!important;
  }

  .newCallOut{
    font-size: 28px!important;
    line-height: 32px!important;
  }
.mintRightSide{
  padding-top:0%!important;
}
  .heroImg,
  .heroImgMint{
    width: 85%!important;
    margin-top: 26%;
    
  }

  #home-slider{
    min-height: 515px;
  }

  .mintTxtTop{
    color:#000!important; 
    font-size:20px!important; 
    font-weight:bold!important;
  }
  
  .mintTxtBtm{
    color:#000!important;
    margin-top:-20px!important; 
    font-family: 'Ariston'!important;
    font-size:45px!important;
    text-shadow: 0 0 5 #ccc!important;
  }

  .rareNum{
    width:100%;
    margin-bottom:20px;
  }

  .rareBox{
    text-align: center;
    font-size:20px!important;
  }
}

.mintTwitterBtn a{
  font-family: 'Ariston'!important;
  color:#000;
  max-width: 200px;
  font-size:30px;
  background-color: yellow;
  padding:10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px blue;;
}
.mintTwitterBtn img{
padding-top:10px;
}
.mainCMB{
  display: none!important;
}
.mintCMB{
  display: block!important;
}
